import { Box } from "@mui/material";
import { BooleanInput, Edit, NumberInput, required, SelectInput, SimpleForm } from "react-admin";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { planningModeChoices } from "shared/enums/reactAdminChoices";
import { validatePlanningTime } from "shared/utils/validations";

export default function PlanningEdit() {
  return (
    <Edit>
      <SimpleForm mode="onBlur">
        <Box
          width={"100%"}
          pl={2}
          pr={2}
          display={"grid"}
          gridTemplateColumns={"repeat(3, 33%)"}
          columnGap={2}
          alignItems="center"
          justifyContent="center"
        >
          <CustomDateTimeInput source="start_date" validate={[required(), validatePlanningTime]} />
          <CustomDateTimeInput source="end_date" validate={[required(), validatePlanningTime]} />
          <SelectInput source="mode" choices={planningModeChoices} validate={required()} />
          <NumberInput source="fcr_engagement" />
          <NumberInput source="target_soc" />
          <NumberInput source="target_power_mw" />
          <NumberInput source="initial_soc_restoration_power" />
          <NumberInput source="soc_restoration_power" />
          <NumberInput source="reactivity_delay" />
          <BooleanInput source="overidable" sx={{ m: "auto" }} />
        </Box>
      </SimpleForm>
    </Edit>
  );
}
