import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  useDataProvider,
  useNotify,
} from "react-admin";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { GroupTypeEnum } from "shared/enums/globalEnums";
import { OptimizerResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import { getBasicErrorMessage } from "shared/utils/errorHandlers";
import { optimizerCertificationsTestsTypeChoices } from "../opti_enums";

interface TransformValues {
  group_id: string;
  [key: string]: any;
}

export default function CertificationsTestsCreate() {
  const notify = useNotify();
  const dataprovider = useDataProvider();

  const onError = (error: Error) => {
    const message = getBasicErrorMessage(error);
    notify(`Error: ${message}`, { type: "error" });
  };

  const transform = async (values: TransformValues) => {
    const { data } = await dataprovider.getOne(SiteResourcesEnum.Groups, {
      id: values.group_id,
    });
    const group_code = data ? data.code : null;
    return {
      ...values,
      group_code,
    };
  };

  return (
    <Create
      mutationOptions={{
        onError,
        onSuccess: () => {
          notify("Certification test created successfully", { type: "success" });
        },
      }}
      transform={transform}
    >
      <SimpleForm>
        <CustomDateTimeInput source="start_date" label="Start Date" fullWidth validate={required()} />
        <CustomDateTimeInput source="end_date" label="End Date" fullWidth validate={required()} />
        <NumberInput source="fcr_engagement" label="FCR engagement" fullWidth validate={required()} />
        <NumberInput source="afrr_engagement" label="AFRR engagement" fullWidth validate={required()} />
        <ReferenceInput source="certif_id" reference={OptimizerResourcesEnum.OptiCertifications}>
          <SelectInput optionText="certif_name" fullWidth validate={required()} label="Certification" />
        </ReferenceInput>
        <SelectInput
          source="test_type"
          label="Test Type"
          choices={optimizerCertificationsTestsTypeChoices}
          fullWidth
          validate={required()}
        />
        <ReferenceInput
          source="group_id"
          reference={SiteResourcesEnum.Groups}
          filter={{
            type: GroupTypeEnum.rte_edp,
          }}
          fullWidth
          label={"Code EDP (rte_edp code)"}
        >
          <AutocompleteInput
            optionText={({ id, name, code }: any) => {
              return `${id} - ${name} - ${code}`;
            }}
            fullWidth
            label={"Code EDP (rte_edp code)"}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
