import { Chip, useTheme } from "@mui/material";
import React from "react";
import { FieldProps, useFieldValue } from "react-admin";
import { getMaintenanceTypeColor } from "../helpers";

const ColoredMaintenanceTypeField = (props: FieldProps) => {
  const muiTheme = useTheme();

  const value = useFieldValue(props);
  const maintenanceColor = getMaintenanceTypeColor(muiTheme)(value);

  const label = value === "undefined" || !value ? "Indéterminé" : value;

  return (
    <Chip
      label={label}
      size="small"
      sx={{
        bgcolor: maintenanceColor,
        color: "white",
      }}
    />
  );
};

export default ColoredMaintenanceTypeField;
