import jsonExport from "jsonexport/dist";
import { useCallback } from "react";
import {
  downloadCSV,
  GetListParams,
  required,
  SimpleForm,
  useDataProvider,
  useNotify,
  useResourceContext,
} from "react-admin";
import { fetchAllRecords } from "shared/components/buttons/CustomExportButton/helpers";
import CustomDialog from "shared/components/dialogs/CustomDialog";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";

type MaintenanceExportDialogFormProps = {
  open: boolean;
  handleClose: () => void;
};

const MaintenanceExportDialogForm = ({ open, handleClose }: MaintenanceExportDialogFormProps) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const resource = useResourceContext() || "";

  const handleNoContextExport = useCallback(
    async (filter: any) => {
      try {
        const config: GetListParams = {
          filter: {
            end__gt: filter.end__gt.toISOString(),
            start__lt: filter.start__lt.toISOString(),
          },
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "ASC" },
        };
        const data = await fetchAllRecords(dataProvider, resource, config, true);

        jsonExport(data, { headers: undefined }, (err, csv) => {
          const now = new Date().toISOString();
          downloadCSV(csv, `${now}_${resource}`);
        });
      } catch (error) {
        console.error("error:", error);
        notify("Error fetching data for export", { type: "warning" });
      }
    },
    [dataProvider, resource, notify],
  );
  return (
    <CustomDialog title={"Export des maintenances"} open={open} onClose={handleClose}>
      <SimpleForm onSubmit={handleNoContextExport}>
        <CustomDateTimeInput source="end__gt" label="Start Date" validate={[required()]} />
        <CustomDateTimeInput source="start__lt" label="End Date" validate={[required()]} />
      </SimpleForm>
    </CustomDialog>
  );
};

export default MaintenanceExportDialogForm;
