import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import {
  Button,
  CreateButton,
  Datagrid,
  FilterButton,
  List,
  ListControllerProps,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useStore,
} from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";
import { DateFieldFr } from "shared/components/fields/DefaultFields";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { MaintenanceResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import useOpenState from "shared/hooks/useOpenState";
import MaintenancesCalendar from "./calendar/MaintenanceCalendar";
import ColoredMaintenanceTypeField from "./components/ColoredMaintenanceTypeField";
import MaintenanceExportDialogForm from "./components/MaintenanceExportDialogForm";

enum ViewEnum {
  calendar = "calendar",
  list = "list",
}

export const MaintenancesList = (props: ListControllerProps) => {
  const { pathname } = useLocation();
  const isSiteShow = pathname.includes("show");
  const navigate = useNavigate();
  const record = useRecordContext();
  // eslint-disable-next-line
  const [_, setSiteShowId] = useStore(`${MaintenanceResourcesEnum.Maintenance}.list.siteShowId`);
  const [view, setView] = useState<ViewEnum>(ViewEnum.list);
  const { open, handleOpen, handleClose } = useOpenState();

  const handleClick = () => {
    setSiteShowId(record?.id || "");
    navigate(`/${MaintenanceResourcesEnum.Maintenance}/create`);
  };

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: ViewEnum) => {
    setView(newView);
  };

  const customFilters = useMemo(
    () => [
      <SearchInput key={"search"} source="search" alwaysOn autoFocus />,
      <TextInput key="type" label="Type" source="type" />,
      <CustomDateTimeInput key="start__gt" label="Start date greater than" source="start__gt" />,
      <CustomDateTimeInput key="start__lt" label="Start date lower than" source="start__lt" />,
      <CustomDateTimeInput key="end__gt" label="End Date greater than" source="end__gt" />,
      <CustomDateTimeInput key="end__lt" label="End Date lower than" source="end__lt" />,
      ...(!isSiteShow
        ? [<ReferenceInput key={"site_id"} label="Site" source="site_id" reference={SiteResourcesEnum.Sites} />]
        : []),
    ],
    [isSiteShow],
  );

  return (
    <Stack direction={"column"} spacing={2}>
      <MaintenanceExportDialogForm open={open} handleClose={handleClose} />

      {!isSiteShow && (
        <ToggleButtonGroup
          color="primary"
          value={view}
          exclusive
          onChange={handleViewChange}
          sx={{
            justifyContent: "center",
            pt: 2,
          }}
        >
          <ToggleButton value={ViewEnum.list}>{ViewEnum.list}</ToggleButton>
          <ToggleButton value={ViewEnum.calendar}>{ViewEnum.calendar}</ToggleButton>
        </ToggleButtonGroup>
      )}

      <List
        {...props}
        title={isSiteShow ? " " : "Maintenance"}
        filters={customFilters}
        filterDefaultValues={{ end__gt: new Date().toISOString() }}
        perPage={50}
        actions={
          <TopToolbar>
            <FilterButton />
            <CreateButton onClick={handleClick} />
            {!isSiteShow && <Button label="Export de maintenances" variant="contained" onClick={handleOpen} />}
          </TopToolbar>
        }
        empty={
          <Box textAlign={"center"} margin={"auto"}>
            <Typography variant="h6" fontStyle={"italic"} gutterBottom color={"grey"}>
              Pas de maintenance en cours
            </Typography>
            <CreateButton onClick={handleClick} />
          </Box>
        }
      >
        {view === ViewEnum.list ? (
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <ReferenceField source="site_id" sortable={false} reference={SiteResourcesEnum.Sites} link="show" />
            <DateFieldFr source="start" sortable={true} showTime />
            <DateFieldFr source="end" sortable={true} showTime />
            <ColoredMaintenanceTypeField source="type" sortable={false} />
            <TextField source="comment" sortable={false} />
            <ReferenceField
              source="site_id"
              sortable={false}
              reference={SiteResourcesEnum.Sites}
              link={false}
              label={"Groups"}
            >
              <ReferenceArrayField
                source="group_ids"
                reference={SiteResourcesEnum.Groups}
                filter={{ type: "rte_edr" }}
              />
            </ReferenceField>
            <DateFieldFr source="updated_at" sortable={false} showTime />
            <DateFieldFr source="created_at" sortable={false} showTime />
            <TextField source="operator" sortable={false} />
          </Datagrid>
        ) : (
          <MaintenancesCalendar />
        )}
      </List>
    </Stack>
  );
};
