import { Datagrid, DateField, List, NumberField, TextField } from "react-admin";

export const EmaxHGateList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <NumberField source="emax_h_gate_id" label={"Emax H Gate ID"} />
      <TextField source="edc_code" />
      <DateField source="end_date" locales={"FR-fr"} showTime />
      <DateField source="start_date" locales={"FR-fr"} showTime />
      <NumberField source="quantity" />
      <TextField source="dso" />
      <DateField source="updated_at" locales={"FR-fr"} showTime />
    </Datagrid>
  </List>
);
