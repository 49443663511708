import { Edit, NumberInput, SimpleForm, TextInput } from "react-admin";
import CustomDateInput from "shared/components/inputs/CustomDateInput";

const TopNiveauGatesEdit = () => (
  <Edit>
    <SimpleForm>
      <CustomDateInput source="target_gate" />
      <CustomDateInput source="application_gate" />
      <TextInput source="deposit_date" />
      <TextInput source="xml_file_link" />
      <NumberInput source="revision_number" />
      <TextInput source="status" />
      <TextInput source="ticket_number" />
    </SimpleForm>
  </Edit>
);

export default TopNiveauGatesEdit;
