import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from "react-admin";
import CustomDateInput from "shared/components/inputs/CustomDateInput";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";

const filters = [<CustomDateInput source="delivery_day" key="delivery_day" alwaysOn mask="dd/MM/yyyy" />];

export const EmaxJGateList = () => (
  <List filters={filters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <NumberField source="emax_j_gate_id" label={"Emax J Gate ID"} />
      <DateField source="delivery_day" locales={"FR-fr"} />
      <TextField source="edc_code" />
      <ReferenceField source="site" reference={SiteResourcesEnum.Sites} />
      <NumberField source="quantity" />
      <TextField source="dso" />
      <DateField source="updated_at" locales={"FR-fr"} showTime />
    </Datagrid>
  </List>
);
