import { defaultTheme } from "react-admin";

const ReTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      light: "#895100",
      ...(process.env.REACT_APP_ENVIRONNEMENT === "staging" ? { main: "#95db1d" } : { main: "#ffb300" }),
      // main: "#ffb300",
      dark: "#ffb86c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#895100",
      main: "#ffb300",
      dark: "#95db1d",
      contrastText: "#fff",
    },
  },
};

export default ReTheme;
