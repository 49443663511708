import {
  AutocompleteArrayInput,
  BooleanInput,
  Datagrid,
  DateField,
  FieldProps,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
  TextInput,
  useFieldValue,
} from "react-admin";
import CustomBooleanField from "shared/components/fields/CustomBooleanField";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";

const statusV2Filters = [
  // ID Filters
  <TextInput key="id" label="ID" source="id" alwaysOn />,
  <TextInput key="id__in" label="ID in" source="id__in" />,
  <TextInput key="id__nin" label="ID not in" source="id__nin" />,

  // Timestamp (ts) Filters
  <CustomDateTimeInput key="ts" label="Timestamp" source="ts" />,
  <CustomDateTimeInput key="ts__neq" label="Timestamp ≠" source="ts__neq" />,
  <CustomDateTimeInput key="ts__gt" label="Timestamp >" source="ts__gt" />,
  <CustomDateTimeInput key="ts__gte" label="Timestamp ≥" source="ts__gte" />,
  <CustomDateTimeInput key="ts__lt" label="Timestamp <" source="ts__lt" />,
  <CustomDateTimeInput key="ts__lte" label="Timestamp ≤" source="ts__lte" />,
  <BooleanInput key="ts__isnull" label="Timestamp Is Null" source="ts__isnull" />,

  // Site ID Filters
  <ReferenceArrayInput key="site_id__in" label="Sites" source="site_id__in" reference={SiteResourcesEnum.Sites}>
    <AutocompleteArrayInput label={"Sites"} />
  </ReferenceArrayInput>,
  <TextInput key="site_id__nin" label="Site ID not in" source="site_id__nin" />,
  <BooleanInput key="site_id__isnull" label="Site ID Is Null" source="site_id__isnull" />,

  // Online Rack Counts Filters
  <NumberInput key="online_rack_counts" label="Online Rack Counts" source="online_rack_counts" />,
  <NumberInput key="online_rack_counts__neq" label="Online Rack Counts ≠" source="online_rack_counts__neq" />,
  <NumberInput key="online_rack_counts__gt" label="Online Rack Counts >" source="online_rack_counts__gt" />,
  <NumberInput key="online_rack_counts__gte" label="Online Rack Counts ≥" source="online_rack_counts__gte" />,
  <NumberInput key="online_rack_counts__lt" label="Online Rack Counts <" source="online_rack_counts__lt" />,
  <NumberInput key="online_rack_counts__lte" label="Online Rack Counts ≤" source="online_rack_counts__lte" />,
  <NumberInput key="online_rack_counts__in" label="Online Rack Counts in" source="online_rack_counts__in" />,
  <NumberInput key="online_rack_counts__nin" label="Online Rack Counts not in" source="online_rack_counts__nin" />,
  <BooleanInput
    key="online_rack_counts__isnull"
    label="Online Rack Counts Is Null"
    source="online_rack_counts__isnull"
  />,

  // Boolean Filters for no_fault
  <BooleanInput key="no_fault" label="No Fault" source="no_fault" />,
  <BooleanInput key="no_fault__neq" label="No Fault ≠" source="no_fault__neq" />,
  <BooleanInput key="no_fault__isnull" label="No Fault Is Null" source="no_fault__isnull" />,

  // Boolean Filters for aggregator_mode
  <BooleanInput key="aggregator_mode" label="Aggregator Mode" source="aggregator_mode" />,
  <BooleanInput key="aggregator_mode__neq" label="Aggregator Mode ≠" source="aggregator_mode__neq" />,
  <BooleanInput key="aggregator_mode__isnull" label="Aggregator Mode Is Null" source="aggregator_mode__isnull" />,

  // Boolean Filters for no_power_clamping
  <BooleanInput key="no_power_clamping" label="No Power Clamping" source="no_power_clamping" />,
  <BooleanInput key="no_power_clamping__neq" label="No Power Clamping ≠" source="no_power_clamping__neq" />,
  <BooleanInput key="no_power_clamping__isnull" label="No Power Clamping Is Null" source="no_power_clamping__isnull" />,

  // Boolean Filters for has_ems_com
  <BooleanInput key="has_ems_com" label="Has EMS Com" source="has_ems_com" />,
  <BooleanInput key="has_ems_com__neq" label="Has EMS Com ≠" source="has_ems_com__neq" />,
  <BooleanInput key="has_ems_com__isnull" label="Has EMS Com Is Null" source="has_ems_com__isnull" />,

  // Boolean Filters for has_data_com
  <BooleanInput key="has_data_com" label="Has Data Com" source="has_data_com" />,
  <BooleanInput key="has_data_com__neq" label="Has Data Com ≠" source="has_data_com__neq" />,
  <BooleanInput key="has_data_com__isnull" label="Has Data Com Is Null" source="has_data_com__isnull" />,

  // Filters for end_date
  <CustomDateTimeInput key="end_date" label="End Date" source="end_date" />,
  <CustomDateTimeInput key="end_date__neq" label="End Date ≠" source="end_date__neq" />,
  <CustomDateTimeInput key="end_date__gt" label="End Date >" source="end_date__gt" />,
  <CustomDateTimeInput key="end_date__gte" label="End Date ≥" source="end_date__gte" />,
  <CustomDateTimeInput key="end_date__lt" label="End Date <" source="end_date__lt" />,
  <CustomDateTimeInput key="end_date__lte" label="End Date ≤" source="end_date__lte" />,
  <BooleanInput key="end_date__isnull" label="End Date Is Null" source="end_date__isnull" />,

  // Filters for duration
  <NumberInput key="duration" label="Duration" source="duration" />,
  <NumberInput key="duration__neq" label="Duration ≠" source="duration__neq" />,
  <NumberInput key="duration__gt" label="Duration >" source="duration__gt" />,
  <NumberInput key="duration__gte" label="Duration ≥" source="duration__gte" />,
  <NumberInput key="duration__lt" label="Duration <" source="duration__lt" />,
  <NumberInput key="duration__lte" label="Duration ≤" source="duration__lte" />,
  <BooleanInput key="duration__isnull" label="Duration Is Null" source="duration__isnull" />,
];

export const Statusesv2List = () => (
  <List filters={statusV2Filters}>
    <Datagrid>
      <TextField source="id" />
      <DateField showTime source="ts" locales={"FR-fr"} />
      <ReferenceField source="site_id" reference={SiteResourcesEnum.Sites} />
      <NumberField source="online_rack_counts" />
      <CustomBooleanField source="no_fault" />
      <CustomBooleanField source="aggregator_mode" />
      <CustomBooleanField source="no_power_clamping" />
      <CustomBooleanField source="has_ems_com" />
      <CustomBooleanField source="has_data_com" />
      <DateField showTime source="end_date" />
      <DurationField source="duration" />
    </Datagrid>
  </List>
);

const DurationField = (props: FieldProps) => {
  const value = useFieldValue(props);
  if (value == null) return <span>-</span>;

  const totalSeconds = Number(value);

  // Compute days
  const days = Math.floor(totalSeconds / 86400);

  // Compute remainder after days
  const remainderAfterDays = totalSeconds % 86400;

  // Compute hours (0-23)
  const hours = Math.floor(remainderAfterDays / 3600);

  // Compute remainder after hours
  const remainderAfterHours = remainderAfterDays % 3600;

  // Compute minutes (0-59)
  const minutes = Math.floor(remainderAfterHours / 60);

  // Compute seconds (0-59)
  const seconds = Math.floor(remainderAfterHours % 60);

  // Compute milliseconds (0-999)
  const milliseconds = Math.floor((totalSeconds * 1000) % 1000);

  return (
    <span>
      {days > 0 && `${days}d `}
      {hours > 0 && `${hours}h `}
      {minutes > 0 && `${minutes}m `}
      {seconds > 0 && `${seconds}s `}
      {milliseconds > 0 && `${milliseconds}ms`}
    </span>
  );
};
