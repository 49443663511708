import { Box, Grid, Stack, Typography } from "@mui/material";
import { addDays, format, startOfDay } from "date-fns";
import { maskModeColors } from "features/charts/planning/helpers";
import { useState } from "react";
import {
  AutocompleteInput,
  Button,
  Loading,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  SimpleForm,
  required,
} from "react-admin";
import ReactApexChart from "react-apexcharts";
import CustomDateInput from "shared/components/inputs/CustomDateInput";
import { PLANNING_MASK_MODE_ENUM } from "shared/enums";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { DateRangeType, FetchInfosType, MaskType } from "shared/types";
import useFetchMasksChartData from "./hooks/useFetchMasksChartData";

type TooltipFormatterPropType = {
  start?: number;
  end?: number;
};

type SeriesType = {
  name: string;
  data: Array<{ x: string; y: [number, number] }>;
};

export default function PlanningMaskChart() {
  const [formPickerState, setFormPickerState] = useState<FetchInfosType>({
    groupIds: [],
    dateRange: {
      startDate: startOfDay(new Date()),
      endDate: addDays(startOfDay(new Date()), 3),
    },
  });

  const { error, loading, masks: rawMasks, edrs: rawEdrs } = useFetchMasksChartData(formPickerState);

  const masks: MaskType[] = rawMasks && rawMasks.length > 0 ? rawMasks : [];
  const edrs = rawEdrs && rawEdrs.length > 0 ? rawEdrs : [];

  const series: SeriesType[] = Object.keys(PLANNING_MASK_MODE_ENUM).map((operatingMode) => ({
    name: operatingMode,
    data: [],
  }));
  console.log("formPickerState:", formPickerState);

  edrs.forEach(({ sites, name: edrName, id: edrId }: any) => {
    const isEdrSelected = formPickerState.groupIds.length === 0 || formPickerState.groupIds.includes(edrId);
    if (sites.length === 0 || !isEdrSelected) {
      return;
    }

    const site = sites[0];
    const siteMasks = masks.filter((mask: MaskType) => mask.site_id === site.id);

    siteMasks.forEach((mask: MaskType) => {
      const maskType = mask.operating_mode;
      const maskData: { x: string; y: [number, number] } = {
        x: edrName,
        y: [new Date(mask.start_date).getTime(), new Date(mask.end_date).getTime()],
      };

      const targetedSerie = series.find((serie) => serie.name === maskType);
      if (targetedSerie) {
        targetedSerie.data.push(maskData);
      }
    });
  });

  const NonEmptySeries: SeriesType[] = series.filter((serie) => serie.data && serie.data.length > 0);

  const fallbackSeries: SeriesType[] = [
    {
      name: "No Data",
      data: [],
    },
  ];

  const state = {
    series: NonEmptySeries.length > 0 ? NonEmptySeries : fallbackSeries,
    options: {
      chart: {
        height: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "75%",
          rangeBarGroupRows: true,
        },
      },
      colors: NonEmptySeries.map((serie) => maskModeColors[serie.name]),
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (value: number) => format(new Date(value), "dd/MM/yyyy HH:mm"),
        },
        min: formPickerState.dateRange?.startDate?.getTime() || new Date().getTime(),
        max: formPickerState.dateRange?.endDate?.getTime() || new Date().getTime(),
      },
      stroke: {
        width: 2,
      },
      fill: {
        type: "solid",
        opacity: 0.6,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      tooltip: {
        x: {
          formatter: function (edrName: string, props: TooltipFormatterPropType | undefined) {
            // eslint-disable-next-line react/prop-types
            if (!edrName || !props || !props.start || !props.end) return "";
            // eslint-disable-next-line react/prop-types
            const { start, end }: TooltipFormatterPropType = props;
            return `${edrName} : ${format(new Date(start), "dd/MM/yyyy HH:mm")} - ${format(
              new Date(end),
              "dd/MM/yyyy HH:mm",
            )}`;
          },
        },
      },
    },
  };

  const handleSubmit = (values: any) => {
    console.log("values:", values);
    const dateRange: DateRangeType = {
      startDate: new Date(values.startDate),
      endDate: addDays(new Date(values.startDate), +values.dateRange),
    };
    setFormPickerState({
      groupIds: [values.groupIds],
      dateRange,
    });
  };

  return (
    <Grid container width={{ xs: "100%", md: "80vw" }} spacing={2}>
      <Grid item xs={12}>
        <SimpleForm
          onSubmit={handleSubmit}
          toolbar={false}
          sx={{
            borderRadius: 2,
            border: "1px solid gainsboro",
            padding: 0,
            pl: 2,
            m: 2,
          }}
        >
          <Box display="flex" justifyContent="space-evenly" gap={2}>
            <CustomDateInput validate={required()} source="startDate" defaultValue={new Date()} fullWidth />
            <ReferenceArrayInput
              reference={SiteResourcesEnum.Groups}
              source="groupIds"
              label="Groups"
              perPage={100}
              filter={{ type: "rte_edp" }}
              defaultValue={formPickerState.groupIds}
            >
              <AutocompleteInput optionText="name" fullWidth />
            </ReferenceArrayInput>
            <RadioButtonGroupInput
              validate={required()}
              source="dateRange"
              choices={[
                { id: "1", name: "1 Day" },
                { id: "3", name: "3 Days" },
                { id: "7", name: "7 Days" },
              ]}
              defaultValue={3}
              row
            />
          </Box>
          <Box margin="auto">
            <Button type="submit" label="Search" variant="contained" size="large" sx={{ width: 300 }} />
          </Box>
        </SimpleForm>
      </Grid>

      <Grid item xs={12} height="100vh" paddingBottom={20}>
        {error && (
          <Stack spacing={2}>
            <Typography variant="h6" color="error" fontWeight="bold">
              Error
            </Typography>
            <Typography color="error">{error.message}</Typography>
          </Stack>
        )}
        {loading ? (
          <Loading />
        ) : NonEmptySeries.length === 0 ? (
          <Typography variant="h6" align="center">
            No data available for the selected filters.
          </Typography>
        ) : (
          <ReactApexChart options={state.options as any} series={state.series} type="rangeBar" height="100%" />
        )}
      </Grid>
    </Grid>
  );
}
