import { Theme } from "@mui/material";
import { MaintenanceTypeEnum } from "shared/enums/globalEnums";

export const getMaintenanceTypeColor = (muiTheme: Theme) => (type: MaintenanceTypeEnum) => {
  const lowercaseType = type.toLowerCase();
  switch (lowercaseType) {
    case MaintenanceTypeEnum.RE:
      return "#95db1d";
    case MaintenanceTypeEnum.Enedis:
      return muiTheme.palette.info.main;
    case MaintenanceTypeEnum.Fortuit:
      return muiTheme.palette.error.main;
    case MaintenanceTypeEnum.Preventive:
      return muiTheme.palette.warning.main;
    default:
      return "gainsboro";
  }
};
