import { DatesSetArg } from "@fullcalendar/core";
import frLocale from "@fullcalendar/core/locales/fr";
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Typography, useTheme } from "@mui/material";
import { Calendar } from "@react-admin/ra-calendar";
import { add, format, parseISO, set, sub } from "date-fns";
import { useCallback, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { MaintenanceTypeEnum } from "shared/enums/globalEnums";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { getMaintenanceTypeColor } from "../helpers";

const MaintenancesCalendar = () => {
  const muiTheme = useTheme();
  const dataprovider = useDataProvider();
  const notify = useNotify();

  const [tooltipContent, setTooltipContent] = useState({
    siteId: "",
    name: "",
    start: "",
    end: "",
    operator: "",
    type: "",
  });
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const getSite = useCallback(
    async (id: string) => {
      const { data } = await dataprovider.getOne(SiteResourcesEnum.Sites, { id });
      return data;
    },
    [dataprovider],
  );

  const handleEventMouseEnter = useCallback(
    async (info: any) => {
      const { siteId, operator, type } = info.event.extendedProps;
      try {
        const { name } = await getSite(siteId);

        setTooltipContent({
          siteId,
          name,
          start: info.event.startStr,
          end: info.event.endStr,
          operator,
          type,
        });

        // Set the position of the tooltip
        const { clientX, clientY } = info.jsEvent;
        setTooltipPosition({ x: clientX, y: clientY });

        // Show the tooltip
        setShowTooltip(true);
      } catch (error) {
        notify("The associated site could not be found", {
          type: "error",
        });
      }
    },
    [getSite, notify],
  );

  const handleEventMouseLeave = useCallback(() => {
    // Hide the tooltip
    setShowTooltip(false);
  }, []);

  const getMaintenanceColor = getMaintenanceTypeColor(muiTheme);

  const converter = useCallback(
    ({ site_id, type, id, start, end, operator }: any) => {
      return {
        id: String(id),
        title: `${site_id}`,
        start,
        end,
        backgroundColor: getMaintenanceColor(type),
        borderColor: "transparent",
        editable: false,
        extendedProps: {
          type,
          siteId: site_id,
          operator,
        },
      };
    },
    [getMaintenanceColor],
  );

  const customGetFilterValues = (dateInfo?: DatesSetArg, filterValues: any = {}): any => {
    const now = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const nowMinus1Month = sub(now, { months: 1 });
    const nowPlus2Months = add(now, { months: 2 });
    //eslint-disable-next-line
    const { start_gte, start_lte, ...values } = filterValues;

    return !dateInfo || (dateInfo.start > nowMinus1Month && dateInfo.end < nowPlus2Months)
      ? {
          ...values,
          start__gte: nowMinus1Month.toISOString(),
          start__lte: nowPlus2Months.toISOString(),
        }
      : {
          ...values,
          start__gte: dateInfo.startStr,
          start__lte: dateInfo.endStr,
        };
  };

  return (
    <>
      {showTooltip && (
        <Box
          sx={{
            position: "absolute",
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            zIndex: 10000,
          }}
        >
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ backgroundColor: getMaintenanceColor(tooltipContent.type as MaintenanceTypeEnum) }}>
                  {tooltipContent.siteId}{" "}
                </Avatar>
              }
              title={tooltipContent.name}
              subheader={tooltipContent.type}
            />
            <Divider />
            <CardContent>
              <Typography variant="body2">
                Start : {format(parseISO(tooltipContent.start), "dd/MM/yyyy HH:mm")}
              </Typography>
              <Typography variant="body2">End : {format(parseISO(tooltipContent.end), "dd/MM/yyyy HH:mm")}</Typography>
              <Typography variant="body2">Operator : {tooltipContent.operator}</Typography>
            </CardContent>
          </Card>
        </Box>
      )}
      <Calendar
        locale={frLocale}
        locales={[frLocale]}
        convertToEvent={converter}
        getFilterValueFromInterval={customGetFilterValues}
        initialView={"timeGridWeek"}
        eventMouseEnter={handleEventMouseEnter}
        eventMouseLeave={handleEventMouseLeave}
      />
    </>
  );
};

export default MaintenancesCalendar;
