import React from "react";
import { Typography } from "@mui/material";

type FlagBaseIconProps = {
  fontSize?: string;
  children: any;
};

export type FlagEmojiIconProps = {
  fontSize?: string;
};

const FlagBaseIcon = ({ fontSize = "20px", children }: FlagBaseIconProps) => {
  return (
    <Typography
      component="span"
      sx={{
        fontSize,
        lineHeight: 1,
      }}
    >
      {children}
    </Typography>
  );
};

export default FlagBaseIcon;
