import { useMemo } from "react";
import { AutocompleteArrayInput, AutocompleteInput, BooleanInput, List, ReferenceInput } from "react-admin";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { enumToChoices } from "shared/utils/reactAdminHelpers";
import { ConfigurationStatusEnum } from "../types";
import PerformanceDatagrid from "./PerformanceDatagrid";

const PerformanceList = () => {
  const configStatusChoices = useMemo(() => enumToChoices(ConfigurationStatusEnum), []);
  const filters = useMemo(
    () => [
      <ReferenceInput source="site_id" key="site_id" reference={SiteResourcesEnum.Sites} perPage={100} alwaysOn>
        <AutocompleteInput label="Site id" />
      </ReferenceInput>,
      <CustomDateTimeInput source="failed_at__gt" key="failed_at__gt" />,
      <CustomDateTimeInput source="failed_at__lt" key="failed_at__lt" />,
      <CustomDateTimeInput source="validated_at__gt" key="validated_at__gt" />,
      <CustomDateTimeInput source="validated_at__lt" key="validated_at__lt" />,
      <AutocompleteArrayInput
        source="configuration_status__in"
        key="configuration_status__in"
        choices={configStatusChoices}
        alwaysOn
        sx={{ width: 300 }}
      />,
      <BooleanInput source="validated_at__isnull" key="validated_at__isnull" />,
      <BooleanInput source="failed_at__isnull" key="failed_at__isnull" />,
    ],
    [configStatusChoices],
  );
  return (
    <List
      sort={{
        field: "failed_at, -validated_at, -created_at",
        order: "DESC",
      }}
      filters={filters}
      filterDefaultValues={{
        failed_at__isnull: true,
      }}
      perPage={50}
      exporter={false}
    >
      <PerformanceDatagrid />
    </List>
  );
};

export default PerformanceList;
