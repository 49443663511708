import { Edit, NumberInput, SelectField, SimpleForm, TextInput } from "react-admin";
import { directionChoices } from "shared/enums/reactAdminChoices";

const TopNiveauBidsEdit = () => (
  <Edit>
    <SimpleForm>
      <NumberInput source="max_quantity" />
      <NumberInput source="min_quantity" />
      <NumberInput source="price" />
      {/* TODO: add ReferenceField once Backend updated */}
      {/* <ReferenceInput source="edr_id" reference={SiteResourcesEnum.Groups} /> */}
      <TextInput source="edr_id" />
      <SelectField source="direction" choices={directionChoices} />
      <NumberInput source="fat" />
      {/* TODO: add ReferenceField once Backend updated */}
      {/* <ReferenceInput source="gate_id" reference={TopNiveauResourcesEnum.TopNiveauGates} /> */}
      <TextInput source="gate_id" />
    </SimpleForm>
  </Edit>
);

export default TopNiveauBidsEdit;
