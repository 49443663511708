import { Card, CardContent, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import {
  Datagrid,
  DateField,
  Form,
  ListContextProvider,
  NumberField,
  SaveButton,
  SortPayload,
  TextField,
  useGetList,
  useList,
} from "react-admin";
import CustomDateInput from "shared/components/inputs/CustomDateInput";
import { EcpResourcesEnum } from "shared/enums/resourceMappings";

const DeliveryDatePoints = () => {
  const [deliveryDate, setDeliveryDate] = useState<Date>();
  const handleSumit = (values: any) => {
    setDeliveryDate(values.deliveryDate);
  };
  return (
    <>
      <Form onSubmit={handleSumit}>
        <Stack spacing={2} sx={{ maxWidth: 300, m: 2 }}>
          <CustomDateInput source="deliveryDate" />
          <SaveButton
            label="Search delivery date"
            sx={{
              marginTop: "0px !important",
            }}
          />
        </Stack>
      </Form>
      {deliveryDate && <PointList deliveryDate={deliveryDate} />}
    </>
  );
};

export default DeliveryDatePoints;

type PointListProps = {
  deliveryDate: Date;
};

const PointList = ({ deliveryDate }: PointListProps) => {
  const urlParams = `/effective/${format(deliveryDate, "yyyy-MM-dd")}`;
  const config = {
    sort: { field: "id", order: "ASC" } as SortPayload,
    pagination: { page: 1, perPage: 100 },
    meta: {
      urlParams,
    },
  };
  const { data, isPending } = useGetList(EcpResourcesEnum.EcpReservePlanPoints, config);
  const listContext = useList({ data, isPending });

  return (
    <ListContextProvider value={listContext}>
      <Card sx={{ m: 2 }}>
        <CardContent>
          <Typography variant="h6">Points</Typography>
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <DateField locales={"FR-fr"} showTime source="start_date" />
            <DateField locales={"FR-fr"} showTime source="end_date" />
            <TextField source="business_type" />
            <TextField source="direction" />
            <NumberField source="quantity_mw" />
            <NumberField source="gate_id" />
          </Datagrid>
        </CardContent>
      </Card>
    </ListContextProvider>
  );
};
