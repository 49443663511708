import { Alert, Box, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import {
  AutocompleteArrayInput,
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SortPayload,
  required,
  useCreate,
  useDataProvider,
  useGetList,
  useNotify,
} from "react-admin";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { SiteContryEnum } from "shared/enums/globalEnums";
import { planningMaskModeChoices, siteCountryChoices } from "shared/enums/reactAdminChoices";
import { PlanningResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import useStatusAware from "shared/hooks/useStatusAware";
import { getDataproviderPydanticErrorMessage, parseBasePydanticErrorMessage } from "shared/utils/errorHandlers";
import { enumToChoices } from "shared/utils/reactAdminHelpers";
import { validateEndDate, validatePlanningTime } from "shared/utils/validations";

export function useFetchSites(edpId: number) {
  return useGetList(SiteResourcesEnum.Groups, {
    pagination: { page: 1, perPage: 100 },
    filter: { id: edpId },
    sort: { field: "id", order: "ASC" } as SortPayload,
  });
}

enum ModeEnum {
  EDP = "EDP",
  Site = "Site",
}

export default function PlanningMaskCreate() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();
  const { error: errors, setError: setErrors } = useStatusAware(false, []);
  const [create]: any = useCreate();
  const [selectedMode, setSelectedMode] = useState(null);

  const modeOptions = useMemo(() => enumToChoices(ModeEnum), []);

  const handleSubmit: SubmitHandler<FieldValues> = async (values) => {
    setErrors([]);
    const { site_id, edp_ids, mode, ...dataBody } = values;

    // on one site
    if (mode === ModeEnum.Site) {
      const siteData = {
        ...dataBody,
        site_id,
        operator_id: 0,
      };
      await create(
        PlanningResourcesEnum.PlanningMasks,
        { data: siteData },
        {
          onSuccess: () => {
            notify("Planning mask created successfully", { type: "success" });
            navigate(`/${PlanningResourcesEnum.PlanningMasks}`);
          },
          onError: (error: any) => {
            const errorMessage = getDataproviderPydanticErrorMessage(error);
            setErrors([errorMessage]);
            notify(errorMessage, { type: "error" });
          },
        },
      );

      // on EDP
    } else {
      try {
        const config = {
          sort: { field: "id", order: "ASC" } as SortPayload,
          pagination: { page: 1, perPage: 100 },
          filter: {
            id__in: edp_ids,
          },
        };
        const { data: edps } = await dataProvider.getList(SiteResourcesEnum.Groups, config);
        const site_ids = edps.reduce((acc: number[], edp: any) => [...acc, ...edp.site_ids], []);

        const createPromises = site_ids.map(async (site_id: any) => {
          return dataProvider.create(PlanningResourcesEnum.PlanningMasks, {
            data: {
              ...dataBody,
              site_id,
              operator_id: 0,
            },
          });
        });

        const results = await Promise.allSettled(createPromises);

        results.forEach((result, index) => {
          if (result.status === "rejected") {
            const errorResponse = parseBasePydanticErrorMessage(result.reason.response.data.detail);

            const errorMessage = `Error updating site ${site_ids[index]}: ${errorResponse}`;
            setErrors((prevState: string[]) => [...prevState, errorMessage]);
            notify(errorResponse, { type: "error" });
          }
        });
        navigate(`/${PlanningResourcesEnum.PlanningMasks}`);
      } catch (error) {
        notify(getDataproviderPydanticErrorMessage(error), { type: "error" });
      }
    }
  };

  return (
    <Create>
      {errors && errors.length > 1 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography fontWeight={"bold"}>Error :</Typography>
          {errors.map((error: string, index: number) => (
            <Typography key={index}>{error}</Typography>
          ))}
        </Alert>
      )}

      <SimpleForm onSubmit={handleSubmit} mode="onBlur">
        <SelectInput source="country" choices={siteCountryChoices} validate={required()} />

        <FormDataConsumer>
          {({ formData }: any) => {
            const isFrance = formData.country === SiteContryEnum.France;
            return (
              <Box>
                {isFrance ? (
                  <Box display={"flex"} columnGap={2}>
                    <SelectInput
                      source="mode"
                      choices={modeOptions}
                      validate={required()}
                      onChange={(e) => setSelectedMode(e.target.value)}
                    />
                    <FormDataConsumer>
                      {() => {
                        if (selectedMode !== null) {
                          switch (selectedMode) {
                            case ModeEnum.EDP:
                              return (
                                <ReferenceArrayInput
                                  source="edp_ids"
                                  reference={SiteResourcesEnum.Groups}
                                  filter={{ type: "rte_edp" }}
                                  perPage={100}
                                >
                                  <AutocompleteArrayInput
                                    label="EDP list"
                                    optionText={({ id, name, code }: any) => {
                                      return `${id} - ${name} - ${code}`;
                                    }}
                                    blurOnSelect={false}
                                    disableCloseOnSelect
                                    helperText={"the more EPDs you select, the longer the loading time"}
                                  />
                                </ReferenceArrayInput>
                              );
                            case ModeEnum.Site:
                              return (
                                <ReferenceInput source="site_id" reference={SiteResourcesEnum.Sites} perPage={100} />
                              );
                          }
                        }
                      }}
                    </FormDataConsumer>

                    <CustomDateTimeInput source="start_date" validate={[required(), validatePlanningTime]} />
                    <CustomDateTimeInput
                      source="end_date"
                      validate={[required(), validatePlanningTime, validateEndDate]}
                    />
                    <SelectInput source="operating_mode" choices={planningMaskModeChoices} validate={required()} />
                  </Box>
                ) : null}
              </Box>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
