import { useMemo } from "react";
import { Datagrid, DateField, List, NumberField, NumberInput, SelectInput, TextField, TextInput } from "react-admin";
import CustomDateInput from "shared/components/inputs/CustomDateInput";
import { topNiveauGateStatusChoices } from "shared/enums/reactAdminChoices";

const TopNiveauGatesList = () => {
  const TopNiveauGatesListFilters = useMemo(
    () => [
      <CustomDateInput key="target_gate" label="Target Gate" source="target_gate" />,
      <CustomDateInput key="target_gate__gte" label="Target Gate After" source="target_gate__gte" />,
      <CustomDateInput key="target_gate__lte" label="Target Gate Before" source="target_gate__lte" />,
      <CustomDateInput key="application_gate__gte" label="Application Gate After" source="application_gate__gte" />,
      <CustomDateInput key="application_gate__lte" label="Application Gate Before" source="application_gate__lte" />,
      <CustomDateInput key="deposit_date" label="Deposit Date" source="deposit_date" />,
      <CustomDateInput key="deposit_date__gte" label="Deposit Date After" source="deposit_date__gte" />,
      <CustomDateInput key="deposit_date__lte" label="Deposit Date Before" source="deposit_date__lte" />,
      <TextInput key="xml_file_link" label="XML File Link" source="xml_file_link" />,
      <NumberInput key="revision_number" label="Revision Number" source="revision_number" />,
      <SelectInput key="status" label="Status" source="status" choices={topNiveauGateStatusChoices} />,
    ],
    [],
  );
  return (
    <List filters={TopNiveauGatesListFilters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField source="created_at" locales={"fr-FR"} showTime />
        <DateField source="updated_at" locales={"fr-FR"} showTime />
        <DateField source="target_gate" locales={"fr-FR"} showTime />
        <DateField source="application_gate" locales={"fr-FR"} showTime />
        <DateField source="deposit_date" locales={"fr-FR"} showTime />
        <TextField source="xml_file_link" />
        <NumberField source="revision_number" />
        <TextField source="status" />
        <TextField source="ticket_number" />
      </Datagrid>
    </List>
  );
};

export default TopNiveauGatesList;
