import { Card, CardContent, CardHeader } from "@mui/material";
import axios from "axios";
import { useCallback } from "react";
import {
  AutocompleteInput,
  CheckboxGroupInput,
  Create,
  Identifier,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  useDataProvider,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import {
  RteCommunicationResourceMapping,
  RteCommunicationResourcesEnum,
  SiteResourcesEnum,
} from "shared/enums/resourceMappings";

export default function FakePasCreate() {
  const dataprovider = useDataProvider();
  const [update]: any = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const fetchGroupCode = useCallback(
    async (id: Identifier) => {
      const { data } = await dataprovider.getOne(SiteResourcesEnum.Groups, {
        id,
      });
      return data;
    },
    [dataprovider],
  );

  const cardContentStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: 2,
  };

  const handleSubmit = useCallback(
    async (values: any) => {
      const edr = await fetchGroupCode(values.edr);
      if (!edr) {
        notify("There was an error fetching the group code", {
          type: "error",
        });
        return;
      }
      const edr_code = edr?.code_tm;

      const isTestMode = values.test_mode.length > 0;
      if (isTestMode) {
        update(SiteResourcesEnum.Groups, {
          id: values.edr,
          data: { mode: "test" },
        });
      }

      try {
        const { status } = await axios.post(
          `${process.env.REACT_APP_IEC_API_URL}/${
            RteCommunicationResourceMapping[RteCommunicationResourcesEnum.RteCommunicationFakePas]
          }`,
          {
            edr_code,
            r1_h_first_value: values.r1_h_first_value || 0,
            r1_h_second_value: values.r1_h_second_value || 0,
            r1_h_timestamp: values.r1_h_timestamp || new Date(),
            r2_h_first_value: values.r2_h_first_value || 0,
            r2_h_second_value: values.r2_h_second_value || 0,
            r2_h_timestamp: values.r2_h_timestamp || new Date(),
          },
        );

        if (status >= 200 && status < 300) {
          notify("Fake PA created successfully", { type: "success" });
          redirect("list", RteCommunicationResourcesEnum.RteCommunicationFakePas);
        }
      } catch (error: any) {
        notify(`Error: ${error.message}`, { type: "error" });
      }
    },
    [fetchGroupCode, notify, redirect, update],
  );

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <ReferenceInput
          source="edr"
          reference={SiteResourcesEnum.Groups}
          filter={{
            type: "rte_edr",
          }}
          perPage={100}
        >
          <AutocompleteInput
            // validate={[required()]}
            optionText={({ id, name, code_tm }: any) => {
              return `${id}. ${name} - Code TM : ${code_tm}`;
            }}
            fullWidth
          />
        </ReferenceInput>

        <CheckboxGroupInput
          source="test_mode"
          choices={[{ id: "test", name: "Test" }]}
          label={"Mode test"}
          helperText={
            "Si cette case est cochée, une requête au service Site sera envoyée pour passer l'EDR en mode test"
          }
          defaultValue={["test"]}
        />

        <Card sx={{ mb: 2 }}>
          <CardHeader title="R1" />
          <CardContent sx={cardContentStyle}>
            <NumberInput source="r1_h_first_value" />
            <NumberInput source="r1_h_second_value" />
            <CustomDateTimeInput source="r1_h_timestamp" />
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="R2" />
          <CardContent sx={cardContentStyle}>
            <NumberInput source="r2_h_first_value" />
            <NumberInput source="r2_h_second_value" />
            <CustomDateTimeInput source="r2_h_timestamp" />
          </CardContent>
        </Card>
      </SimpleForm>
    </Create>
  );
}
