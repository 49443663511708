import { Box, Container, Stack, Typography } from "@mui/material";
import { AppLocationContext, Breadcrumb, SolarLayout, SolarLayoutProps, SolarMenu } from "@react-admin/ra-navigation";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { breadcrumbInfos, menuItems } from "./MenuItems";

const CustomSolarMenu = () => {
  const location = useLocation();

  const isActiveMenu = useCallback(
    (item: any) => {
      const subItemsLabels = item.listItems.map((subItem: any) => subItem.path);
      const subSubItemsLabels = item.listItems
        .map((subItem: any) => {
          if (subItem.listItems) {
            return subItem.listItems.map((subSubItem: any) => subSubItem.path);
          }
          return [];
        })
        .flat();

      const locationRoot = location.pathname.split("/")[1];
      const allSubMenuLabels = [...subItemsLabels, ...subSubItemsLabels];
      return allSubMenuLabels.includes(locationRoot);
    },
    [location.pathname],
  );

  const renderMenuItems = useCallback(
    (items: any[]) => {
      return items.map((item, index) => {
        const isActive = isActiveMenu(item);
        return (
          <SolarMenu.Item
            key={index}
            label={item.label}
            icon={item.icon}
            name={item.label}
            sx={{
              bgcolor: isActive ? "primary.main" : "white",
            }}
            subMenu={
              <SolarMenu.List dense disablePadding sx={{ gap: 0 }}>
                <Typography variant="h6" sx={{ px: 1, my: 1 }}>
                  {item.label}
                </Typography>
                {item.listItems.map((subItem: Record<string, string | any>) => {
                  return subItem.listItems ? (
                    <Box key={subItem.label}>
                      <Typography sx={{ px: 2 }}>{subItem.label}</Typography>
                      {subItem.listItems.map((subSubItem: Record<string, string>) => {
                        return (
                          <SolarMenu.Item
                            key={subSubItem.path}
                            name={subSubItem.path}
                            to={subSubItem.path}
                            label={subSubItem.label}
                            sx={{ px: 3 }}
                          />
                        );
                      })}
                    </Box>
                  ) : (
                    <SolarMenu.Item key={subItem.path} name={subItem.path} to={subItem.path} label={subItem.label} />
                  );
                })}
              </SolarMenu.List>
            }
          />
        );
      });
    },
    [isActiveMenu],
  );

  return (
    <SolarMenu
      sx={{
        "& .RaSolarPrimarySidebar-root > .MuiDrawer-paper": {
          background: "primary.main",
        },
      }}
    >
      {renderMenuItems(menuItems)}
    </SolarMenu>
  );
};

const CustomBreadcrumb = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.split("/")[1];
  const breadcrumbBase = breadcrumbInfos[currentPath];

  return (
    <Stack direction="row" spacing={1} pt={"4px"}>
      {breadcrumbBase?.map((label, index) => (
        <Typography key={index} variant="h6">
          {label} /
        </Typography>
      ))}
    </Stack>
  );
};

const CustomSolarLayout = ({ children, ...props }: SolarLayoutProps) => {
  return (
    <SolarLayout {...props} menu={CustomSolarMenu}>
      <AppLocationContext>
        <Container maxWidth={false}>
          <Stack direction="row" spacing={1}>
            <CustomBreadcrumb />
            <Breadcrumb
              sx={{
                "& .MuiTypography-root": { fontSize: 18 },
                "& span": { color: "primary.main" },
              }}
            />
          </Stack>
          {children}
        </Container>
      </AppLocationContext>
    </SolarLayout>
  );
};

export default CustomSolarLayout;
